const elements = document.querySelectorAll('.c-iv');
const threshold = 0.5; // Start Position(0.1=10%))
const resetAnimation = false; // Animation Reset : true or false

function init() {
  if (elements.length === 0) return;

  // console.log('InView!!!!!');
  handleInView();
}

function handleInView() {
  const observer = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add('js-iv-animate');
      } else if (resetAnimation) { // Out of ViewPort > Animation Reset
        entry.target.classList.remove('js-iv-animate');
      }
    });
  }, {
    threshold: threshold
  });

  elements.forEach(element => {
    observer.observe(element); // Check Element!
  });
}

export default {
  init,
};
