function init() {

  const menuBtn = document.querySelector('.l-header__menuBtn');
  const targetElement = document.querySelector('.l-headerSpNav');

  toggleMenuActive(menuBtn, targetElement);
}

function toggleMenuActive(menuBtn, targetElement) {
  if (menuBtn && targetElement) {
    menuBtn.addEventListener('click', () => {
      menuBtn.classList.toggle('is-active');
      targetElement.classList.toggle('is-active');
    });
  }
}

export default {
  init,
};
