function init() {
  const $el_hero = document.querySelector('.p-topHero');
  if ($el_hero) {
    initHeroMedia();
    parallax();
  }
}

// ------------------------------------------------------------
// 動画もしくは画像読み込みで再生 → アニメーションスタート
// ------------------------------------------------------------
function initHeroMedia() {
  const heroVideo = document.querySelector('.p-topHero video');
  const heroImg = document.querySelector('.p-topHero img');

  // 動画または画像の準備ができたらアニメーションを開始
  Promise.any([
    new Promise((resolve) => {
      if (heroVideo) {
        heroVideo.addEventListener('canplaythrough', () => {
          heroVideo.play();
          resolve(); // 動画準備完了
        });
      }
    }),
    new Promise((resolve) => {
      if (heroImg) {
        const onImageLoad = () => {
          heroImg.classList.add('is-loaded'); // 画像準備完了時にクラスを追加
          resolve(); // 画像準備完了
        };

        heroImg.addEventListener('load', onImageLoad);

        if (heroImg.complete && heroImg.naturalWidth > 0) {
          onImageLoad(); // キャッシュからの読み込みにも対応
        }
      }
    }),
  ]).then(() => {
    animateHeroCopy(); // 準備完了後にアニメーションを開始

    // 動画の準備が完了したら is-loaded クラスを追加
    if (heroVideo) {
      heroVideo.classList.add('is-loaded');
    }
  });
}

// ------------------------------------------------------------
// Text Animation
// ------------------------------------------------------------
function animateHeroCopy() {
  const elements = document.querySelectorAll('.p-topHero__copy__e span, .p-topHero__copy__j');
  if (!elements.length) return;

  const [first, second, third] = elements;

  // 1番目のアニメーション
  setTimeout(() => {
    first.classList.add('is-active');
  }, 1000); // 0.5秒後

  // 2番目のアニメーション
  setTimeout(() => {
    second.classList.add('is-active');
  }, 1000); // 1秒後

  // 3番目のアニメーション（フェードイン）
  setTimeout(() => {
    third.classList.add('is-active');
  }, 2500); // 4秒後 (2の開始から3秒後)
}

// ------------------------------------------------------------
// Main Image Parallax
// ------------------------------------------------------------
function parallax() {
  const heroImg = document.querySelector('.p-topHero video');
  const main = document.querySelector('.p-topMain');

  if (!heroImg || !main) return;
  const mainOffsetTop = main.offsetTop;
  window.addEventListener('scroll', () => {
    const scrollY = window.scrollY;
    // スクロール量に応じて画像のY位置を変化
    if (scrollY < mainOffsetTop) {
      const moveAmount = scrollY * 0.4; // スクロール量の40%だけ移動
      heroImg.style.transform = `translate(-50%, calc(-50% + ${moveAmount}px))`;
    }
  });
}




// ------------------------------------------------------------
// Google Map API
// ------------------------------------------------------------
function initMap() {
  // マップのスタイルをカスタマイズ
  const customMapStyle = [
    {
      elementType: 'geometry',
      stylers: [{ color: '#ebe3cd' }]
    },
    {
      elementType: 'labels.text.fill',
      stylers: [{ color: '#523735' }]
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [{ color: '#f5f1e6' }]
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [{ color: '#c9c9c9' }]
    }
  ];

  // マップの初期設定
  const map = new google.maps.Map(document.getElementById('map'), {
    center: { lat: 35.6895, lng: 139.6917 }, // 東京の緯度経度
    zoom: 14,
    styles: customMapStyle // スタイルを適用
  });

  // オリジナルマーカー（ピン）の設定
  const markerIcon = {
    url: 'path/to/your-logo.png', // オリジナルロゴのパス
    scaledSize: new google.maps.Size(50, 50) // サイズ調整
  };

  // マーカーを配置
  const marker = new google.maps.Marker({
    position: { lat: 35.6895, lng: 139.6917 }, // ピンの場所
    map: map,
    icon: markerIcon // カスタムアイコンを指定
  });
}




export default {
  init,
};
